import { DigitalInput } from "../../../models/digitalinput";

interface Props {
  digitalInput: DigitalInput;
}

const StationOnTimesRow = ({ digitalInput }: Props) => {
  return (
    <tr>
      <th scope="row">{digitalInput.Point}</th>
      <td>{digitalInput.Config.OnTimeDesc}</td>
      <td>{digitalInput.State.OnTimeTotal.toFixed(1)}</td>
      <td>{digitalInput.State.OnTimeToday.toFixed(1)}</td>
      <td>{digitalInput.State.OnTimeYday.toFixed(1)}</td>
    </tr>
  );
};

export default StationOnTimesRow;
