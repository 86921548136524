import axios, { AxiosError, AxiosResponse } from "axios";
import { toast } from "react-toastify";
import AppSettings from "../helpers/app-settings";
import { AlarmLogResponse } from "../models/alarm";
import {
  Station,
  StationConfigResponse,
  StationCreateRequest,
  StationFullConfig,
  StationSelect,
} from "../models/station";
import {
  StationReportDaily,
  StationReportForm,
  StationReportHourly,
  StationList,
} from "../models/station-list";
import { StationMapResponse } from "../models/station-map";
import {
  StationOverview,
  StationOverviewList,
} from "../models/station-overview";
import {
  AccountStationCreateRequest,
  AccountStationResponse,
  AccountStationUpdateRequest,
  User,
  UserCreateRequest,
  UserResponse,
  UserUpdateRequest,
} from "../models/user";

// Create the Axios instance with defaults
const instance = axios.create({
  baseURL: AppSettings.ApiBaseUrl,
  withCredentials: true,
});

// Response interceptor to handle error responses
instance.interceptors.response.use(
  (response: any) => {
    return response;
  },
  (error: AxiosError) => {
    const { status } = error.response!;
    switch (status) {
      case 400:
        if (error.response?.config.url === "/accounts/refresh-token") {
          break;
        }
        toast.error("Bad Request");
        break;
      case 401:
        if (error.response?.config.url === "/accounts/refresh-token") {
          toast.error("Unauthorized");
          break;
        } else {
          return Users.refresh();
        }
      case 404:
        toast.error("Not found");
        break;
      case 500:
        toast.error("Server error");
        break;
    }

    return Promise.reject(error);
  }
);

// Request interceptor to include the bearer token
// for authorization on all requests
instance.interceptors.request.use(
  (config) => {
    if (config.url !== "/accounts/refresh-token") {
      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${localStorage.getItem(
          "token"
        )}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// This skips the step of retrieving the data from each response
// and this will always return just the data
const responseBody = <T>(response: AxiosResponse<T>) => response.data;

// The requests available to this application wrapping
// axios with the defaults this app uses and generics
const requests = {
  get: <T>(url: string) => instance.get<T>(url).then(responseBody),
  post: <T>(url: string, body: {}) =>
    instance
      .post<T>(url, body, { headers: { "Content-Type": "application/json" } })
      .then(responseBody),
  put: <T>(url: string, body: {}) =>
    instance
      .put<T>(url, body, { headers: { "Content-Type": "application/json" } })
      .then(responseBody),
  delete: <T>(url: string) => instance.delete<T>(url).then(responseBody),
};

// Users requests
const Users = {
  login: (email: string, password: string) =>
    requests.post<User>(
      "/accounts/authenticate",
      JSON.stringify({
        email: email,
        password: password,
      })
    ),
  logout: () => requests.post("/accounts/revoke-token", {}),
  refresh: () => requests.post<User>("/accounts/refresh-token", {}),
  list: () => requests.get<UserResponse[]>("/accounts"),
  detail: (id: string) => requests.get<UserResponse>(`/accounts/${id}`),
  update: (id: string, user: UserUpdateRequest) =>
    requests.put<UserResponse>(`/accounts/${id}`, user),
  create: (user: UserCreateRequest) =>
    requests.post<UserResponse>("/accounts", user),
  delete: (id: string) => requests.delete(`/accounts/${id}`),
};

// Stations requests
const Stations = {
  list: () => requests.get<StationOverview[]>("/api/Stations/overview"),
  listall: () => requests.get<Station[]>("/api/Stations"),
  all: () => requests.get<StationConfigResponse[]>("/api/Stations/all"),
  overview: () =>
    requests.get<StationOverviewList[]>("/api/Stations/overviewlist"),
  detail: (id: string) => requests.get<Station>(`/api/Stations/${id}`),
  config: (id: string) =>
    requests.get<StationFullConfig>(`/api/Stations/config/${id}`),
  create: (station: StationCreateRequest) =>
    requests.post<Station>("/api/Stations", station),
  update: (station: StationFullConfig) =>
    requests.put<StationFullConfig>("/api/Stations/config", station),
  map: () => requests.get<StationMapResponse[]>("/api/Stations/map"),
  poll: (id: string) => requests.get<Station>(`/api/Stations/poll/${id}`),
  names: () => requests.get<StationSelect[]>("/api/Stations/list"),
  alarms: (start: string, end: string, stations: string) =>
    requests.get<AlarmLogResponse[]>(
      `/api/Stations/alarm/${start}/${end}/${stations}`
    ),
};

// Reports requests
const Reports = {
  list: () => requests.get<StationList[]>("/api/Stations/list"),
  countForm: (id: string) =>
    requests.get<StationReportForm[]>(`/api/Stations/list/count/${id}`),
  countHourly: (id: string, start: string, end: string) =>
    requests.get<StationReportHourly[]>(
      `/api/Stations/report/count/${start}/${end}/${id}`
    ),
  countDaily: (id: string, date: string) =>
    requests.get<StationReportDaily[]>(
      `/api/Stations/report/count/${date}/${id}`
    ),
  ontimeForm: (id: string) =>
    requests.get<StationReportForm[]>(`/api/Stations/list/ontime/${id}`),
  ontimeDaily: (id: string, date: string) =>
    requests.get<StationReportDaily[]>(
      `/api/Stations/report/ontime/${date}/${id}`
    ),
  offtimeForm: (id: string) =>
    requests.get<StationReportForm[]>(`/api/Stations/list/offtime/${id}`),
  offtimeDaily: (id: string, date: string) =>
    requests.get<StationReportDaily[]>(
      `/api/Stations/report/offtime/${date}/${id}`
    ),
};

const AccountStations = {
  list: () => requests.get<AccountStationResponse[]>("/api/AccountStations"),
  create: (accountStations: AccountStationCreateRequest[]) =>
    requests.post("/api/AccountStations", accountStations),
  update: (accountStations: AccountStationUpdateRequest[]) =>
    requests.put("/api/AccountStations", accountStations),
  delete: (id: string) => requests.delete(`/api/AccountStations/${id}`),
};

// Create a requests agent with the defined types
const agent = {
  Users,
  Stations,
  Reports,
  AccountStations,
};

export default agent;
