import {
  DateTimePicker,
  DateTimePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { DateTime } from "luxon";
import { StationOverview } from "../../models/station-overview";
import LxCard from "../layout/LxCard";
import { MultiSelect } from "react-multi-select-component";

interface Props {
  stations: StationOverview[];
  onGenerateAlarmLog: (start: Date, end: Date, stations: string) => void;
}

interface Option {
  label: string;
  value: number;
  disabled?: boolean;
}

const AlarmLogForm = ({ stations, onGenerateAlarmLog }: Props) => {
  const monthStart = DateTime.local().startOf("month");

  const [start, setStart] = useState<Date | undefined>(
    new Date(monthStart.toISO())
  );
  const [end, setEnd] = useState<Date | undefined>(new Date());
  const options = stations.map((station) => {
    return {
      label: `${station.StationId} - ${station.SiteName}`,
      value: station.StationId,
    };
  });
  const [selected, setSelected] = useState<Option[]>([]);

  const disabled = !start && !end && !selected;

  const generateReportHandler = () => {
    if (start && end) {
      onGenerateAlarmLog(
        start,
        end,
        selected.map((station) => station.value).join(",")
      );
    }
  };

  const startChangeHandler = (event: DateTimePickerChangeEvent) => {
    if (event.value) {
      setStart(event.value);
    }
  };

  const endChangeHandler = (event: DateTimePickerChangeEvent) => {
    if (event.value) {
      setEnd(event.value);
    }
  };

  return (
    <LxCard
      title="Select Date and Stations"
      footer={
        <Button onClick={generateReportHandler} disabled={disabled}>
          Generate
        </Button>
      }
    >
      <Row className="mb-3">
        <Col lg={4}>
          <label htmlFor="startpicker" className="form-label mt-4">
            Start Date
          </label>
          <DateTimePicker
            onChange={startChangeHandler}
            value={start}
            max={end}
            id="startpicker"
            width="100%"
          />
        </Col>
        <Col lg={4}>
          <label htmlFor="endpicker" className="form-label mt-4">
            End Date
          </label>
          <DateTimePicker
            onChange={endChangeHandler}
            value={end}
            min={start}
            id="endpicker"
            width="100%"
          />
        </Col>
        <Col lg={4}>
          <label htmlFor="stationSelect" className="form-label mt-4">
            Station
          </label>
          <MultiSelect
            options={options}
            value={selected}
            onChange={setSelected}
            labelledBy="Select Stations"
          />
        </Col>
      </Row>
    </LxCard>
  );
};

export default AlarmLogForm;
